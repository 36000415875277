import React from "react";
import node from "./img/node.js.svg";
import ruby from "./img/ruby.svg";
import android from "./img/android.svg";
import aws from "./img/awsq.webp";
import cplusplus from "./img/cplusplus.svg";
import ailg from "./img/star.svg";

const ContactIgn = () => {
  return (
    <div className="  ">
      <div className="container py-3">
        <div className="my-3 text-center">
          <div className="">
            <div>
              <h1 className="text-white qaw font2 text-center">
                Need assistance with a <span className="aitxt">software project?</span>
              </h1>
            </div>
            <div>
              <p className="text-white">
                Whether you're looking for expert developers or a full-service development solution, we're here to help. Get in touch!
              </p>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-md-8 ">
            <div className="blck2 p-4 ">
              <form>
                <div className="row mb-1">
                  <div className="col-md-6">
                    <label htmlFor="firstName" className="form-label">
                      First Name
                    </label>
                    <input
                      type="text"
                      id="firstName"
                      className="form-control inputfrom"
                      placeholder="Enter your first name"
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="lastName" className="form-label">
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="lastName"
                      className="form-control inputfrom"
                      placeholder="Enter your last name"
                      required
                    />
                  </div>
                </div>

                <div className="row mb-1">
                  <div className="col-md-4">
                    <label htmlFor="countryCode" className="form-label ">
                      Country Code
                    </label>
                    <select id="countryCode" className="form-select inputfrom" required>
                    <option value="+91">+91 (India)</option>
                      <option value="+1">+1 (USA)</option> 
                      <option value="+44">+44 (UK)</option>
                      <option value="+61">+61 (Australia)</option>
                      <option value="+81">+81 (Japan)</option>
                    </select>
                  </div>
                  <div className="col-md-8">
                    <label htmlFor="phoneNumber" className="form-label">
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      id="phoneNumber"
                      className="form-control inputfrom"
                      placeholder="Enter your phone number"
                      required
                    />
                  </div>
                </div>

                <div className="mb-1">
                  <label htmlFor="email" className="form-label">
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="form-control inputfrom"
                    placeholder="Enter Your Email"
                    required
                  />
                </div>

                <div className="mb-1">
                  <label htmlFor="message" className="form-label">
                    Message
                  </label>
                  <textarea
                    id="message"
                    className="form-control inputfrom inputfromtxt"
                    rows="5"
                    placeholder="Type your message here"
                    required
                  ></textarea>
                </div>

                <div className="text-center">
                  <button type="submit" className="hirebtn2 ">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-4">
            <div className="blck2 p-4">
               <h6>Email Us at:</h6>
               <p className="m-0">Contact@ignsoftwares.in</p>
               <p>Support@ignsoftwares.in</p>


               <h6>Call Us at:</h6>
               <p className="m-0"></p>
               <p>+91 853 078 0242</p>


            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactIgn;
