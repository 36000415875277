import React from "react";
import { Link } from "react-router-dom";
import {
  FaUsers,
  FaLaptopCode,
  FaCode,
  FaRobot,
  FaMobileAlt,
  FaInfinity,
  FaClipboardList,
} from "react-icons/fa";
import ailg from "./img/star.svg";
import imgaa from "./img/imgaa.webp";


const BookNow = () => {
  return (
    <div className="">
      <div className="container py-5">
        <div className="my-3">

        <div className="text-dark d-flex">
            <span>
              <span className="star-block d-inline-block">
                <img src={ailg} alt="" className="star1" />
                <img src={ailg} alt="" className="star2" />
                <img src={ailg} alt="" className="star3" />
              </span>
            </span>
            <span>
              <strong>Consultation Today</strong>
            </span>
          </div>
          <h1 className="text-dark qaw font2">
          Get{" "}
            <span className="aitxt"> Now</span>
          </h1>
          
          {/* <h1 className="text-dark udrl qaw">
            Services we provide, but not limited to...
          </h1> */}
        </div>
        

        <div className="bgconsult d-flex justify-content-between align-items-center mt-5">
          <div className="">
            <div className="conslt qaw font2 text-white">Book Your Free Consultation Today ..!!</div>
            <p className="text-white">
              Book an appointment to explore how we can enhance your team's
              productivity and streamline your operations!
            </p>
            <Link to="/" className="hirebtn">
              Free Consultation
            </Link>
          </div>
          <div>
            <img src={imgaa} className="img-fluid l12" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookNow;
